// import React from "react";
// import { Link } from "gatsby"


// const tabs = [
//   { id: "food", label: "食品" },
//   { id: "retail", label: "卸売/小売" },
//   { id: "public", label: "公共/運輸" },
//   { id: "construction", label: "建設/不動産" },
//   { id: "manufacturing", label: "製造業" },
//   { id: "finance", label: "金融/IT/サービス" },
// ];


// const Tabs = ({ activeTab, setActiveTab }) => {

//   console.log("Active Tab:", activeTab) 
//   const handleTabClick = (tabId) => {
//     if (window.location.pathname === `/industries/${tabId}`) {
//       setActiveTab(tabId);
//     } else {
//       window.location.href = `/industries/${tabId}`;
//     }
//   };

//   return (
//     <section className="area-tabs">
//       <div className="area-tabs__inner">
//           <ul className="area-tabs__wrap">
//            {tabs.map((tab) => (
//            <li
//               key={tab.id}
//               className={`area-tabs__tab tab_button ${activeTab === tab.id ? "tab-active" : ""}`}
//             >
//               <Link 
//                 to={`/industries/${tab.id}`}
//                 onClick={() => handleTabClick(tab.id)}
//                  activeClassName="tab-active"
//                  className="tab-link"
//               >
//                 {tab.label}
//               </Link>
//             </li>
            
//             ))}
//         </ul>
//       </div>
//     </section>
//   );
// };

// export default Tabs;

import React from "react"
import { Link } from "gatsby"

const tabs = [
  { id: "food", label: "食品" },
  { id: "retail", label: "卸売/小売" },
  { id: "public", label: "公共/運輸" },
  { id: "construction", label: "建設/不動産" },
  { id: "manufacturing", label: "製造業" },
  { id: "finance", label: "金融/IT/サービス" },
]

const Tabs = ({ activeTab }) => {
  return (
    <section className="area-tabs">
      <div className="area-tabs__inner">
        <ul className="area-tabs__wrap">
          {tabs.map((tab) => (
            <li
              key={tab.id}
              className={`area-tabs__tab tab_button ${activeTab === tab.id ? "tab-active" : ""}`}
            >
              <Link
                to={`/industries/${tab.id}`}
                className="tab-link"
              >
                {tab.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Tabs
